<template>
  <div :class="tabsClasses">
    <ul>
      <li
        v-for="t in tabs"
        :key="t.name"
        :class="activeClass(t.name)"
      >
        <a
          @click="activate(t.name)"
        >
          {{ t.label }}
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeTab: null
    }
  },
  props: {
    tabs: {
      type: Array,
      required: true
    },
    value: {
      type: String,
      required: true
    },
    size: {
      type: String,
      default: "medium"
    }
  },
  mounted() {
    // set the default active tab
    if (this.value && this.value.length > 0) {
      this.activeTab = this.value
    } else if (this.tabs.length > 0) {
      this.activeTab = this.tabs[0].name
    }
  },
  computed: {
    tabsClasses() {
      const classes = ["tabs"]
      switch(this.size) {
        case "small":
          classes.push("is-small")
          break
        case "medium":
          classes.push("is-medium")
          break
        case "large":
          classes.push("is-large")
          break
      }
      return classes.join(" ")
    },
  },
  methods: {
    activeClass(name) {
      return (this.activeTab == name) ? "is-active" : ""
    },
    activate(name) {
      this.activeTab = name
      this.$emit('input', this.activeTab)
    }
  }
}
</script>
