<template>
  <div class="table-container">
    <Notification
      v-if="error"
      type="danger"
    >
      {{ error }}
    </Notification>

    <b-table
      :data="data"
      :bordered="true"
      :striped="true"
      :narrowed="true"
      :hoverable="true"
      default-sort="submitted_at"
      default-sort-direction="desc"
    >
      <b-table-column
        field="submitted_at"
        label="Date"
        sortable
        v-slot="props"
      >
        {{ props.row.submitted_at }}
      </b-table-column>

      <b-table-column
        field="id"
        label="Batch"
        sortable
        v-slot="props"
      >
        <a @click.prevent="batchPayout(props.row)">
          {{ props.row.id }}
        </a>
      </b-table-column>

      <b-table-column
        field="submitted_to_paypal"
        label="Submitted?"
        sortable
        v-slot="props"
      >
        {{ (props.row.submitted_to_paypal) ? "Yes" : "No" }}
      </b-table-column>

      <b-table-column
        field="paypal_payouts.length"
        label="Payouts"
        numeric
        sortable
        v-slot="props"
      >
        {{ props.row.paypal_payouts.length }}
      </b-table-column>

      <b-table-column
        field="email_subject"
        label="Subject"
        v-slot="props"
      >
        {{ props.row.email_subject }}
      </b-table-column>

      <b-table-column
        label=""
        v-slot="props"
      >
        <button
          class="button"
          :disabled="!props.row.submitted_to_paypal"
          @click="refreshBatchPayoutStatuses(props.row)"
        >
          Refresh Statuses
        </button>
      </b-table-column>

      <template #empty>
        <div class="has-text-centered">No PayPal payout batches</div>
      </template>
    </b-table>

    <form
      v-if="showBatchPayout"
      @submit.prevent="submitBatchPayout(currentBatchPayout)"
    >
      <BaseModal
        title="PayPal Payouts Batch"
        @close="hideBatchPayout()"
      >
        <BaseInput
          label="Subject"
          :value="currentBatchPayout.email_subject"
          :disabled="!canSubmitBatchPayout(currentBatchPayout)"
        />
        <BaseTextarea
          label="Message"
          :value="currentBatchPayout.email_message"
          :disabled="!canSubmitBatchPayout(currentBatchPayout)"
        />
        <p
          v-for="(total, currency) in batchPayoutTotals(currentBatchPayout)"
          :key="currency"
        >
          <strong>Batch Payout Total:</strong>
          <span>{{ USD(total).format() }} {{ currency }}</span>
        </p>
        <AdminPaypalPayoutsTable
          :data="currentBatchPayout.paypal_payouts"
        />
        <template v-slot:footer>
          <button
            class="button"
            @click="hideBatchPayout()"
          >
            Close
          </button>
          <BaseSubmit
            label="Submit"
            :disabled="!canSubmitBatchPayout(currentBatchPayout)"
            :submitting="submittingBatchPayout"
            :clearShow="false"
          />
        </template>
      </BaseModal>
    </form>
  </div>
</template>

<script>
import Notification from "@/components/Notification.vue";
import BaseModal from "@/components/modal/BaseModal.vue";
import BaseInput from "@/components/form/BaseInput.vue";
import BaseSubmit from "@/components/form/BaseSubmit.vue";
import BaseTextarea from "@/components/form/BaseTextarea.vue";
import AdminPaypalPayoutsTable from "@/components/table/AdminPaypalPayouts.vue";
import api from "@/service/api.js";

export default {
  components: {
    Notification,
    BaseModal,
    BaseInput,
    BaseSubmit,
    BaseTextarea,
    AdminPaypalPayoutsTable
  },
  props: {
    data: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      error: null,
      showBatchPayout: false,
      submittingBatchPayout: false,
      currentBatchPayout: null
    }
  },
  computed: {
    currentUser: function() {
      return this.$store.getters.currentUser;
    },
  },
  methods: {
    batchPayout(batch) {
      this.currentBatchPayout = batch
      this.showBatchPayout = true
    },
    hideBatchPayout() {
      this.submittingBatchPayout = false
      this.showBatchPayout = false
      this.currentBatchPayout = null
    },
    batchPayoutTotals(batch) {
      const currencies = [...new Set(batch.paypal_payouts.map(payout => payout.currency))]
      const totals = {}
      currencies.forEach((currency) => {
        totals[currency] = batch.paypal_payouts.reduce((total, paypalPayout) => {
          return (paypalPayout.currency == currency) ? total + paypalPayout.amount + paypalPayout.payout_item_fee_amount : total
        }, 0)
      })
      return totals
    },
    canSubmitBatchPayout(batch) {
      return (batch.paypal_payouts.length && !batch.submitted_to_paypal)
    },
    submitBatchPayout(batch) {
      this.submittingBatchPayout = true

      api.paypalBatchSubmit(this.currentUser.slug, batch)
        .then(resp => {
          console.log(resp)
          this.hideBatchPayout()
          this.$emit('change')
        })
        .catch(error => {
          this.error = (error.data) ? error.data : "An unknown error occurred while submitting PayPal Payouts batch!"
          this.hideBatchPayout()
        })
    },
    refreshBatchPayoutStatuses(batch) {
      api.paypalBatchRefresh(this.currentUser.slug, batch)
        .then(resp => {
          console.log(resp)
          this.$emit('change')
        })
        .catch(error => {
          this.error = (error.data) ? error.data : "An unknown error occurred while refreshing PayPal Payouts batch!"
        })
    }
  }
};
</script>
