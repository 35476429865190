<template>
  <div class="table-container">
    <table class="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
      <thead>
        <tr>
          <th>Date</th>
          <th>Status</th>
          <th>Paypal Account</th>
          <th>Amount Transfered</th>
          <th>Paypal Fee</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="r in data"
          :key="r.id"
        >
          <td>{{ r.submitted_at }}</td>
          <td>{{ r.status }}</td>
          <td>{{ r.recipient_id }}</td>
          <td>{{ USD(r.amount).format() }} {{ r.currency }}</td>
          <td>{{ USD(r.payout_item_fee_amount).format() }} {{ r.payout_item_fee_currency }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      required: true
    }
  },
};
</script>
