<template>
  <AdminPage>
    <Notification
      v-if="success"
      type="success"
    >
      {{ success }}
    </Notification>
    <Notification
      v-if="warning"
      type="warning"
    >
      {{ warning }}
    </Notification>
    <Notification
      v-if="error"
      type="danger"
    >
      {{ error }}
    </Notification>

    <Tabs
      :tabs="tabs"
      v-model="activeTab"
      size="small"
    />

    <div v-if="activeTab === 'payouts'">
      <nav class="level">
        <!-- Left side -->
        <div class="level-left">
          <div class="level-item">
            <div class="field has-addons">
              <p class="control">
                <span class="select">
                  <select
                    v-model="payoutsFilter"
                    @change="updatePayouts"
                  >
                    <option value="">All payouts</option>
                    <option value="unsubmitted">Unsubmitted payouts</option>
                    <option value="unclaimed">Unclaimed payouts</option>
                  </select>
                </span>
              </p>
            </div>
          </div>
        </div>
        <!-- Right side -->
        <div class="level-right">
          <div class="level-item">
            <ConfirmButton
              v-if="payoutsFilter == 'unclaimed'"
              title="Cancel All Unclaimed Payouts"
              buttonLabel="Cancel All Unclaimed Payouts"
              :submitting="submittingCancelPayouts"
              :disabled="payouts.length == 0"
              @confirm="cancelUnclaimedPayouts"
            >
              Are you sure you want to cancel all unclaimed payouts?
            </ConfirmButton>
          </div>
          <p class="level-item"><strong>{{ payoutsCount }}</strong></p>
        </div>
      </nav>

      <AdminPayoutsTable
        :data="payouts"
        @change="updatePayouts"
      />
    </div>

    <div v-if="activeTab === 'paypal_batches'">
      <nav class="level">
        <!-- Left side -->
        <div class="level-left">
          <div class="level-item">
          </div>
        </div>
        <!-- Right side -->
        <div class="level-right">
          <div class="level-item">
            <ConfirmButton
              buttonLabel="Generate PayPal Payouts Batch"
              @confirm="generateBatchPayouts"
              :submitting="submittingGenerateBatchPayouts"
            >
              Are you sure your want to generate new payouts and a PayPal Payouts batch?
            </ConfirmButton>
          </div>
          <p class="level-item"><strong>{{ paypalBatchesCount }}</strong></p>
        </div>
      </nav>

      <AdminPaypalPayoutBatchesTable
        :data="paypalBatches"
        @change="updatePaypalBatches"
      />
    </div>
  </AdminPage>
</template>

<script>
import AdminPage from "@/components/AdminPage.vue";
import Notification from "@/components/Notification.vue";
import Tabs from "@/components/Tabs.vue";
import ConfirmButton from "@/components/ConfirmButton.vue";
import AdminPayoutsTable from "@/components/table/AdminPayouts.vue";
import AdminPaypalPayoutBatchesTable from "@/components/table/AdminPaypalPayoutBatches.vue";
import api from "@/service/api.js";

export default {
  components: {
    AdminPage,
    Notification,
    Tabs,
    ConfirmButton,
    AdminPayoutsTable,
    AdminPaypalPayoutBatchesTable
  },
  computed: {
    currentUser: function() {
      return this.$store.getters.currentUser;     
    },
    payoutsCount: function() {
      return this.payouts.length
    },
    paypalBatchesCount: function() {
      return this.paypalBatches.length
    }
  },
  data() {
    return {
      success: null,
      warning: null,
      error: null,
      // tabs
      tabs: [
        {
          name: "payouts",
          label: "Payouts"
        },
        {
          name: "paypal_batches",
          label: "PayPal Payouts Batches"
        }
      ],
      activeTab: 'payouts',
      // batch payouts
      submittingGenerateBatchPayouts: false,
      // payouts
      submittingCancelPayouts: false,
      payoutsFilter: "",
      payouts: [],
      // paypal batches
      paypalBatches: []
    }
  },
  mounted() {
    this.updatePayouts();
    this.updatePaypalBatches();
  },
  methods: {
    // payouts methods
    updatePayouts() {
      api.getAdminPayouts(this.currentUser.slug, this.payoutsFilter)
        .then(resp => {
          this.payouts = resp.data
        })
        .catch(error => {
          this.error = error.data
        })
    },
    // cancel unclaimed payouts methods
    cancelUnclaimedPayouts() {
      api.payoutsCancelUnclaimed(this.currentUser.slug)
        .then(() => {
          this.success = "Cancelled all unclaimed payouts"
          this.updatePayouts()
        })
        .catch(error => {
          this.error = error.data
        })
    },
    // generate batch payouts methods
    generateBatchPayouts() {
      this.submittingGenerateBatchPayouts = true

      api.paypalBatchCreate(this.currentUser.slug)
        .then(resp => {
          if (!resp.data.batch && resp.data.message) {
            this.warning = resp.data.message
          } else if (resp.data.batch && resp.data.message) {
            this.success = resp.data.message
          }

          this.submittingGenerateBatchPayouts = false

          this.updatePaypalBatches()
        })
        .catch(error => {
          this.error = (error.data) ? error.data : "An unknown error occurred while generating a PayPal Payouts batch"
          this.submittingGenerateBatchPayouts = false
        })
    },
    // batch payout methods
    updatePaypalBatches() {
      api.getAdminPayouts(this.currentUser.slug, this.payoutsFilter)
        .then(resp => {
          this.payouts = resp.data
        })
        .catch(error => {
          this.error = error.data
        })
      api.getAdminPaypalBatches(this.currentUser.slug)
        .then(resp => {
          this.paypalBatches = resp.data
        })
        .catch(error => {
          this.error = error.data
        })
    }
  }
}
</script>
