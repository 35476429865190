<template>
  <div class="table-container">
    <Notification
      v-if="error"
      type="danger"
    >
      {{ error }}
    </Notification>

    <b-table
      :data="data"
      :bordered="true"
      :striped="true"
      :narrowed="true"
      :hoverable="true"
      default-sort="id"
      default-sort-direction="desc"
    >
      <b-table-column
        field="id"
        label="ID"
        sortable
        v-slot="props"
      >
        {{ props.row.id }}
      </b-table-column>

      <b-table-column
        field="paypal_payout.submitted_at"
        label="Date"
        sortable
        v-slot="props"
      >
        {{ (props.row.paypal_payout) ? props.row.paypal_payout.submitted_at : "" }}
      </b-table-column>

      <b-table-column
        field="paypal_payout"
        label="Type"
        sortable
        v-slot="props"
      >
        {{ (props.row.paypal_payout) ? "PayPal" : "" }}
      </b-table-column>

      <b-table-column
        field="status"
        label="Status"
        sortable
        v-slot="props"
      >
        {{ props.row.status }}
      </b-table-column>

      <b-table-column
        field="user_id"
        label="User"
        sortable
        v-slot="props"
      >
        {{ props.row.user_id }}
      </b-table-column>

      <b-table-column
        field="amount"
        label="Amount"
        sortable
        v-slot="props"
      >
        {{ USD(props.row.amount).format() }} {{ props.row.currency }}
      </b-table-column>

      <b-table-column
        field="paypal_payout.payout_item_fee_amount"
        label="Fee"
        sortable
        v-slot="props"
      >
        <span v-if="props.row.paypal_payout">
          {{ USD(props.row.paypal_payout.payout_item_fee_amount).format() }} {{ props.row.paypal_payout.payout_item_fee_currency }}
        </span>
      </b-table-column>

      <b-table-column
        label=""
        v-slot="props"
      >
        <ConfirmButton
          v-if="props.row.paypal_payout"
          title="Cancel Payout"
          buttonLabel="Cancel"
          :submitting="submittingCancelPayout"
          :disabled="!canCancelPayout(props.row)"
          @confirm="cancelPayout(props.row)"
        >
          <p>Are you sure your want to cancel this {{ USD(props.row.amount).format() }} {{ props.row.currency }} payout to the PayPal account {{ props.row.paypal_payout.recipient_id }} from batch {{ props.row.paypal_payout.sender_batch_id }}?</p>
        </ConfirmButton>
        <ConfirmButton
          title="Delete Payout"
          buttonLabel="Delete"
          :submitting="submittingDeletePayout"
          :disabled="!canDeletePayout(props.row)"
          @confirm="deletePayout(props.row)"
        >
          <p>Are you sure you want to delete this {{ USD(props.row.amount).format() }} {{ props.row.currency }} payout?</p>
        </ConfirmButton>
      </b-table-column>

      <template #empty>
        <div class="has-text-centered">No payouts</div>
      </template>
    </b-table>
  </div>
</template>

<script>
import Notification from "@/components/Notification.vue";
import ConfirmButton from "@/components/ConfirmButton.vue";
import api from "@/service/api.js";

export default {
  components: {
    Notification,
    ConfirmButton
  },
  props: {
    data: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      error: null,
      submittingCancelPayout: false,
      submittingDeletePayout: false,
      currentPayout: null
    }
  },
  computed: {
    currentUser: function() {
      return this.$store.getters.currentUser
    },
  },
  methods: {
    canCancelPayout(payout) {
      return (payout.status == "unclaimed")
    },
    canDeletePayout(payout) {
      return (payout.status == "unsubmitted")
    },
    cancelPayout(payout) {
      if (!this.canCancelPayout(payout)) {
        this.error = `You cannot cancel payout ${payout.id}!`
        return
      }

      this.submittingCancelPayout = true

      api.cancelPayout(this.currentUser.slug, payout.id)
        .then(resp => {
          console.log(resp)
          this.success = `Successfully cancelled payout ${payout.id}`
          this.submittingCancelPayout = false
          this.$emit('change')
        })
        .catch(error => {
          console.log(error)
          this.error = `An error occurred while attempting to cancel payout "${payout.id}!`
          this.submittingCancelPayout = false
        })
    },
    deletePayout(payout) {
      if (!this.canDeletePayout(payout)) {
        this.error = `You cannot cancel payout ${payout.id}!`
        return
      }

      this.submittingDeletePayout = true

      api.deletePayout(this.currentUser.slug, payout.id)
        .then(resp => {
          console.log(resp)
          this.success = `Successfully deleted payout ${payout.id}`
          this.submittingDeletePayout = false
          this.$emit('change')
        })
        .catch(error => {
          console.log(error)
          this.error = `An error occurred while attempting to delete payout ${payout.id}!`
          this.submittingDeletePayout = false
        })
    }
  }
};
</script>
